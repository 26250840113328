import "./App.css";
import ForceLayout from "./Components/ForceLayout";
import React from "react";

function App() {
  return (
    <div className="App">
      <ForceLayout width={1080} height={750} />
    </div>
  );
}

export default App;
